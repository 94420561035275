import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import './App.css';

function WalletInfo() {
  const [account, setAccount] = useState('');
  const [balance, setBalance] = useState('');

  const loadWalletData = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();

      if (accounts.length > 0) {
        const account = accounts[0];
        const balance = await web3.eth.getBalance(account);

        setAccount(`${account.substring(0, 6)}...${account.substring(account.length - 4)}`);
        setBalance(web3.utils.fromWei(balance, 'ether'));
      } else {
        setAccount('');
        setBalance('0.0000');
      }
    }
  };

  useEffect(() => {
    loadWalletData();

    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        setAccount('');
        setBalance('0.0000');
      } else {
        loadWalletData();
      }
    };

    window.ethereum.on('accountsChanged', handleAccountsChanged);
    window.ethereum.on('chainChanged', loadWalletData);

    // Bakiyeyi d�zenli olarak g�ncellemek i�in zamanlayici ayarla
    const balanceInterval = setInterval(() => {
      loadWalletData();
    }, 1000); // Her 10 saniyede bir bakiyeyi kontrol et

    // Component unmount edildiginde event listenerlari ve zamanlayiciyi kaldir
    return () => {
      if (window.ethereum.removeListener) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', loadWalletData);
      }
      clearInterval(balanceInterval);
    };
  }, []);

  const formattedBalance = balance ? parseFloat(balance).toFixed(4) : '0.0000';

  return (
    <div className="wallet-info">
      <p>Address: {account}</p>
      <p>{formattedBalance} ENZ</p>
      <button className="faucet-button" onClick={() => window.open('https://faucet-playground.vercel.app/', '_blank')}>
        Faucet $ENZ
      </button>
            <div>
               {/* Marketplace sayfasina y�nlendirme butonu */}
    <div className="explorer-button">
      <button onClick={() => window.open('https://froopyland.online', '_blank')}>Explorer</button>
    </div>
        <div className="rollapp-button">
      <button onClick={() => window.open('https://portal.dymension.xyz/rollapp/aazifirii_2932875-1', '_blank')}>RollApp</button>
    </div>
    <div className="froopyland-button">
      <button onClick={() => window.open('https://t.me/froopyland_bot', '_blank')}>Telegram Bot</button>
    </div>
        <div className="wiki-button">
      <button onClick={() => window.open('https://rollape-quest.gitbook.io/enzifiri-network-wiki/', '_blank')}>Wiki</button>
    </div>
    </div>
    </div>
  );
}

export default WalletInfo;
