import React, { useState, useEffect } from 'react';
import './App.css';
import Web3 from 'web3';
import MyContractABI from './MyContractABI.json';
import { useSpring, animated } from 'react-spring';
import WalletInfo from './WalletInfo'; // WalletInfo'yu import et
import NftMint from './NftMint'; // Nftminti importla
import SimpleTokenRewardABI from './SimpleTokenRewardABI.json';
import Popup from './Popup'; // Popup bileşenini import et

const CONTRACT_ADDRESS = '0xbde3D0eAE8Cdc762bCaD460E11Ac4F7C2C9313e2';
const SIMPLE_TOKEN_REWARD_ADDRESS = '0xe99a3206d140fe4b5c33A59d4368dE388dfC7937';


window.onerror = function (message, source, lineno, colno, error) {
  console.error("Bir hata yakalandı:", message, source, lineno, colno, error);
  // Hata detaylarını bir sunucuya göndermek için AJAX isteği veya benzeri bir yöntem kullanabilirsiniz.
  return true; // Bu, tarayıcının varsayılan hata yöneticisini devre dışı bırakır.
};

window.onunhandledrejection = function (event) {
  console.error("Yakalanmamış bir Promise reddi:", event.reason);
  // Promise reddinin detaylarını sunucuya göndermek için burada işlem yapabilirsiniz.
  return true;
};

const networks = {
  rollapequest: {
    chainId: '0x407',
    chainName: 'Rollape Quest',
    nativeCurrency: {
      name: 'ENZ',
      symbol: 'ENZ',
      decimals: 18
    },
    rpcUrls: ['https://evm.thin-cobra-98.telebit.io'],
    blockExplorerUrls: ['https://froopyland.online/'],
  }
};

function Warrior({ isAttacking }) {
  const attackAnimation = useSpring({
    to: { transform: isAttacking ? 'translateX(100px)' : 'translateX(0px)' },
    from: { transform: 'translateX(0px)' },
  });

  return <animated.div style={attackAnimation}>
           <img src="/images/warrior.png" alt="Warrior" />
         </animated.div>;
}

function Modal({ show, onClose, children }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {children}
        <div className="button-center">
<div className="button-lets-do">
  <button onClick={onClose}>Lets Do!</button>
</div>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [contract, setContract] = useState(null);
  const [error, setError] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [count, setCount] = useState(0);
  const [isAttacking, setIsAttacking] = useState(false);
  const [enemyHealth, setEnemyHealth] = useState(100);
  const [showImages, setShowImages] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [notification, setNotification] = useState('');
  const [setWeb3] = useState(null);
  const [rewardContract, setRewardContract] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  
    useEffect(() => {
    const initializeRewardContract = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        const rewardContractInstance = new web3.eth.Contract(SimpleTokenRewardABI, SIMPLE_TOKEN_REWARD_ADDRESS);
        setRewardContract(rewardContractInstance);
      }
    };

    initializeRewardContract();
  }, []);

const claimReward = async () => {
  if (rewardContract && accounts.length > 0) {
    try {
      const gasLimit = 50000; // Örnek bir gas limit değeri
      await rewardContract.methods.claimReward().send({
        from: accounts[0],
        gas: gasLimit
      });
      setNotification('Reward claimed successfully!');
      setShowPopup(false); // Popup ekranını kapat
    } catch (error) {
      console.error('Error claiming reward:', error);
      setNotification('Failed to claim reward: ' + error.message);
    }
  } else {
    setNotification('Contract not loaded or no account connected!');
  }
};
  
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        setWeb3(new Web3(window.ethereum)); // web3 nesnesini başlat ve state'e at
        setNotification('Wallet successfully connected.');
      } catch (error) {
        console.error("Please Refresh Website:", error);
        setNotification('Already Connected.');
      }
    } else {
      console.log("Wallet not found. Try again.");
      setNotification('Wallet not found.');
    }
  };
    
  useEffect(() => {
    const initializeWeb3 = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          setAccounts(accounts);
          const myContractInstance = new web3.eth.Contract(MyContractABI, CONTRACT_ADDRESS);
          setContract(myContractInstance);
          getCount(myContractInstance); // Akıllı kontrattan count değerini al
        } catch (error) {
          console.error('', error);
          setError('');
        }
      } else {
        console.log('Metamask is not installed!');
        setError('Metamask is not installed!');
      }
    };

    initializeWeb3();
  }, []);
  
useEffect(() => {
  if (enemyHealth <= 0) {
    setPopupData({
      name: "Dragon Defeated",
      imageUrl: "/images/dragon-defeated.png",
      // Burada ekstra bir alan ekleyebilirsiniz, örneğin:
      // showclaimRewardButton: true
      
    });
    setShowPopup(true);
  }
}, [enemyHealth]);

useEffect(() => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    const simpleTokenContract = new web3.eth.Contract(SimpleTokenRewardABI, SIMPLE_TOKEN_REWARD_ADDRESS);
    setContract(simpleTokenContract);
  }
}, []);



const switchNetwork = async () => {
  if (accounts.length > 0) {
    try {
      await changeNetwork('rollapequest');
      setNotification('Network switched successfully.');
    } catch (error) {
      console.error("Failed to switch network:", error);
      setNotification('Failed to switch network: ' + error.message);
    }
  } else {
    console.log("Wallet not connected.");
    setNotification("Please refresh Website.");
  }
};

const changeNetwork = async (networkName) => {
  if (!window.ethereum) throw new Error("No crypto wallet found");
  await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [{ ...networks[networkName] }]
  });
};
   
const getCount = async (contractInstance) => {
  if (contractInstance) {
    const response = await contractInstance.methods.count().call();
    setCount(Number(response.toString())); // BigInt'ten String'e, sonra Number'a dönüşüm
  }
};
  
  const increaseCounter = async () => {
    if (contract && accounts.length > 0) {
      try {
      await contract.methods.increment().send({ from: accounts[0] });
      getCount(contract); // Sayıyı arttırdıktan sonra count değerini tekrar al
      setIsAttacking(true);
      setTimeout(() => setIsAttacking(false), 1000); // 1 saniye sonra saldırıyı durdur
      setEnemyHealth((prevHealth) => prevHealth - 25);
      setNotification('Attack successful. Keep Going!');
    } catch (error) {
      console.error('An error occurred while attacking!', error);
      setNotification('Attack failed: ' + error.message);
    }
  } else {
    console.log('Contract object not loaded or accounts not loaded!');
    setNotification('Contract object not loaded or accounts not loaded!');
  }
};
    useEffect(() => {
    setShowImages(enemyHealth <= 0); // Düşmanın canı 0 ise görselleri göster
  }, [enemyHealth]);

  // Düşman komponenti
  function Enemy({ health }) {
    const healthBarWidth = health + '%';

    return (
      <div className="enemy" style={{ opacity: health > 0 ? 1 : 0 }}>
        <div className="enemy-health-bar">
          <div className="enemy-health" style={{ width: healthBarWidth }}></div>
        </div>
        <img src="/images/enemy.png" alt="Enemy" />
      </div>
    );
  }
    // Bildirim mesajını kaldırmak için bir fonksiyon
  const clearNotification = () => {
    setTimeout(() => {
      setNotification('');
    }, 3000); // 3 saniye sonra bildirimi kaldır
  };

  // Bildirim mesajı değiştiğinde, otomatik olarak kaldırma işlemini tetikle
  useEffect(() => {
    if (notification) {
      clearNotification();
    }
  }, [notification]);
  
    useEffect(() => {
    if (enemyHealth <= 0) {
      setPopupData({
        name: "Dragon Defeated",
        imageUrl: "/images/dragon-defeated.png", // Örnek bir görsel URL'si
      });
      setShowPopup(true); // Popup'ı göster
    }
  }, [enemyHealth]);
  
  
// Arka plandaki görselleri ve Claim Reward butonunu render etme

// eslint-disable-next-line 
    {showImages && (
      <div className="background-images">
        <img src="/images/1.png" alt="cit1" />
        <img src="/images/2.png" alt="cit2" />
        <img src="/images/3.png" alt="cit3" />
        <img src="/images/4.png" alt="cit4" />
      </div>
    )
    }
  
return (
  <div className="App">
    {notification && <div className="toast">{notification}</div>} {/* Bildirim mesajını göster */}
    
    {/* Modal */}
    <Modal show={showModal} onClose={() => setShowModal(false)}>
      <img src="/images/scroll.png" alt="scrollpop" />
    </Modal>

    {/* Wallet Info, Network Switch ve Header */}
    <WalletInfo />
    <div className="button">
      <button onClick={connectWallet}>Connect Wallet</button>
    </div>   
    <div className="button">
      <button onClick={switchNetwork}>Switch Network</button>
    </div>
    <header className="App-header">
      {error && <p>Hata: {error}</p>}
      <p>Total Damage: {count}</p>
    </header>
    
      {/* Savaş Alanı */}
      <div className="battlefield">
        <Warrior isAttacking={isAttacking} />
        {enemyHealth > 0 ? <Enemy health={enemyHealth} /> : (
          <div className="background-images">
            {/* Düşmanın yerine gösterilecek görseller */}
            <img src="/images/1.png" alt="cit1" />
            <img src="/images/2.png" alt="cit2" />
            <img src="/images/3.png" alt="cit3" />
            <img src="/images/4.png" alt="cit4" />
          </div>
        )}
      
           {showPopup && (
     <Popup 
  show={showPopup}
  onClose={() => setShowPopup(false)}
  data={popupData}
  onClaimReward={claimReward} // onclaimReward yerine onClaimReward kullanın
/>
    )}
    
      </div>
    )}

    {/* Diğer Bileşenler */}
    <div className="button-attack">
      <button onClick={increaseCounter}>Attack!</button>
    </div>
    <NftMint />
    <div className="info-text">
      <p>Press the Attack button repeatedly to make a combo</p>
    </div>
  </div>
);
}

export default App;
