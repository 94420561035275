import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import NftContractABI from './NftContractABI.json';
import './NftMint.css'; // CSS dosyasini import edin
import Popup from './Popup'; // Popup bilesenini import edin. Yolu projenize g�re ayarlayin.

const CONTRACT_ADDRESS = '0x70A5337A6d848C2f438a702b8Bd222252fd7de77';
const NFT_URI = 'ipfs://bafkreidlxjgajc3rvhic6mu7sf42eojozw64dcxdawuwi3wnclmiseorh4';

function NftMint() {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [message, setMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({});

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      setContract(new web3Instance.eth.Contract(NftContractABI, CONTRACT_ADDRESS));
    }
  }, []);

  const mintNFT = async () => {
    if (!contract || !web3) {
      showMessage("Contract is not initialized.");
      return;
    }

    const accounts = await web3.eth.getAccounts();
    if (accounts.length === 0) {
      showMessage("No account is connected.");
      return;
    }

    const tokenId = Math.floor(Math.random() * 10000000000); // 10 haneli rastgele sayi
try {
  const response = await contract.methods.safeMint(accounts[0], tokenId, NFT_URI).send({ from: accounts[0] });
  setPopupData({
    name: "Froopy Man Minted", // Ger�ek NFT adini buraya ekleyin
    txHash: response.transactionHash,
    imageUrl: "/images/onizle.png" // `public` klas�r�ndeki g�rselin yolu
  });
  setShowPopup(true);
  showMessage(`NFT Minted successfully, Thank you!`);
} catch (error) {
  console.error("Minting failed: ", error);
  showMessage("Minting failed: " + error.message);
}

};
      
  
  const showMessage = (msg) => {
    setMessage(msg);  
    setTimeout(() => {
    setMessage('');
  }, 5000);
};

  return (
  <div className="nft-mint-button">
    <button onClick={mintNFT}>Mint NFT</button>
    {message && <div className={`toast ${message === 'toast-hide' ? 'toast-hide' : ''}`}>{message !== 'toast-hide' ? message : ''}</div>}
    {/* Popup bilesenini ekleyin */}
     <Popup 
        show={showPopup} 
        onClose={() => setShowPopup(false)} 
        data={popupData}
      />
  </div>
);
}

export default NftMint;
