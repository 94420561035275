import React from 'react';

function Popup({ show, onClose, data, onClaimReward }) {
  if (!show) {
    return null;
  }

  // Eger data.txHash varsa, NFT islemi i�in URL olustur
  const txUrl = data.txHash ? `https://froopyland.online/tx/${data.txHash}` : null;

  return (
    <div className="popup">
      <div className="popup-content">
        <h3>{data.name}</h3>
        <img src={data.imageUrl} alt="NFT or Dragon" />

        {/* Eger onClaimReward fonksiyonu varsa, Claim Reward butonunu g�ster */}
        {onClaimReward && (
          <button onClick={onClaimReward} className="claim-reward-button">Claim 5$ENZ</button>
        )}

        {/* Eger txUrl varsa, View NFT butonunu g�ster */}
        {txUrl && (
          <button onClick={() => window.open(txUrl, "_blank")} className="tx-button">View NFT</button>
        )}
        
        {/* Close butonu */}
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
}

export default Popup;
